export const NAV_ITEMS: {
  [key: string]: {
    key: string;
    path: string;
    langKey: keyof IntlMessages["components"]["Layout"]["nav"];
    disabled: boolean;
  };
} = {
  // dashboard: {
  //   key: "dashboard",
  //   path: "/dashboard",
  //   langKey: "startPage",
  //   disabled: false,
  // },
  code: {
    key: "code",
    path: "/dashboard?code",
    langKey: "enter_code",
    disabled: false,
  },
  // shop: {
  //   key: "shop",
  //   path: "/shop",
  //   langKey: "shop",
  //   disabled: true,
  // },
  abo: {
    key: "co2_abo",
    path: "/co2-abo",
    langKey: "co2_abo",
    disabled: true,
  },
  calc: {
    key: "co2_calculator",
    path: "/co2-calculator",
    langKey: "co2_calculator",
    disabled: false,
  },
};

import { Menu, MenuItem } from "@szhsin/react-menu";
import clsx from "clsx";
import { useRouter } from "next/router";
import useUtilTranslations from "../../hooks/useUtilTranslations";
import styles from "./LanguageMenu.module.scss";

type LanguageMenuProps = {};

function LanguageMenu({}: LanguageMenuProps) {
  const tUtil = useUtilTranslations();
  const router = useRouter();

  const goToLocale = (locale: string) => {
    return router.push(
      { pathname: router.pathname, query: router.query },
      router.asPath,
      { locale: locale }
    );
  };

  return (
    <Menu
      arrow
      align="end"
      direction="bottom"
      menuButton={
        <div className={styles.button}>
          {router.locale === "en" ? (
            <EnFlag className={styles.menuIcon} />
          ) : (
            <DeFlag className={styles.menuIcon} />
          )}
        </div>
      }
    >
      <MenuItem
        onClick={() => goToLocale("en")}
        className={clsx(router.locale === "en" && styles.activeLang)}
      >
        <EnFlag className={styles.buttonIcon} />
        {tUtil("languages.en")}
      </MenuItem>
      <MenuItem
        onClick={() => goToLocale("de")}
        className={clsx(router.locale === "de" && styles.activeLang)}
      >
        <DeFlag className={styles.buttonIcon} />
        {tUtil("languages.de")}
      </MenuItem>
    </Menu>
  );
}

const EnFlag = ({ className }: { className?: string }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="flag-icons-gb"
    viewBox="0 0 640 480"
    // style={{ width: "1em", height: "1em" }}
    className={className}
  >
    <path fill="#012169" d="M0 0h640v480H0z" />
    <path
      fill="#FFF"
      d="m75 0 244 181L562 0h78v62L400 241l240 178v61h-80L320 301 81 480H0v-60l239-178L0 64V0z"
    />
    <path
      fill="#C8102E"
      d="m424 281 216 159v40L369 281zm-184 20 6 35L54 480H0zM640 0v3L391 191l2-44L590 0zM0 0l239 176h-60L0 42z"
    />
    <path fill="#FFF" d="M241 0v480h160V0zM0 160v160h640V160z" />
    <path fill="#C8102E" d="M0 193v96h640v-96zM273 0v480h96V0z" />
  </svg>
);

const DeFlag = ({ className }: { className?: string }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="flag-icons-de"
    viewBox="0 0 640 480"
    // style={{ width: "1em", height: "1em" }}
    className={className}
  >
    <path fill="#fc0" d="M0 320h640v160H0z" />
    <path fill="#000001" d="M0 0h640v160H0z" />
    <path fill="red" d="M0 160h640v160H0z" />
  </svg>
);

export default LanguageMenu;

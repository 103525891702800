import clsx from "clsx";
import Link, { LinkProps } from "next/link";
import { ButtonHTMLAttributes } from "react";
import styles from "./Button.module.scss";

export type ButtonProps = Omit<
  ButtonHTMLAttributes<HTMLButtonElement>,
  "color"
> & {
  color?: ButtonColor;
  loading?: boolean;
};

export type ButtonColor = "green" | "red" | "white";

function Button({
  className,
  color = "white",
  loading,
  ...props
}: ButtonProps) {
  return (
    <button
      className={clsx(
        className,
        styles.base,
        color && styles[color],
        loading && styles.loading
      )}
      disabled={loading}
      {...props}
    />
  );
}

export type ButtonLinkProps = Pick<
  ButtonProps,
  "color" | "className" | "loading" | "children"
> &
  Pick<LinkProps, "onClick" | "href">;

export const ButtonLink = ({
  className,
  color = "white",
  loading,
  ...props
}: ButtonLinkProps) => {
  return (
    <Link
      {...props}
      className={clsx(
        className,
        styles.base,
        color && styles[color],
        loading && styles.loading
      )}
      // disabled={loading}
    />
  );
};

// export const ButtonLink = forwardRef(_ButtonLink);

export default Button;

import { CodeProjectRelation, Profile, Translation } from "@prisma/client";
import { createHash } from "crypto";
import { addYears, isAfter, isThisMonth } from "date-fns";
import { NextRouter } from "next/router";
import { FormEvent } from "react";
import { DateTimeFormatOptions } from "use-intl";
import {
  type CellLinkObject,
  type CellObject,
} from "../components/table/Table";

export function noop() {}

export function urlB64ToUint8Array(base64String: string) {
  if (!base64String || base64String === "")
    throw new Error("Please provide string parameter");
  const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding).replace(/-/g, "+").replace(/_/g, "/");

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}

export function hasField(obj: any | undefined, key: string): boolean {
  return (Array.isArray(obj) || typeof obj === "object") && key in obj;
}

export function isObject(item: any): item is Object {
  return item && typeof item === "object" && !Array.isArray(item);
}

export function mergeDeep(target: any, source: any) {
  let output = Object.assign({}, target);
  if (isObject(target) && isObject(source)) {
    Object.keys(source).forEach((key) => {
      if (isObject(source[key])) {
        if (!(key in target)) Object.assign(output, { [key]: source[key] });
        else output[key] = mergeDeep(target[key], source[key]);
      } else {
        Object.assign(output, { [key]: source[key] });
      }
    });
  }
  return output;
}

// FIXME: implement some sort of password pattern!
export function checkPasswordPattern(password?: string): boolean {
  return true;
}

export function AppTitle(fix?: { prefix?: string; suffix?: string }) {
  return `${fix?.prefix || ""}Hello Green Friends${fix?.suffix || ""}`;
}

export function calcReductionCurrentMonth(
  relations?: CodeProjectRelation[] | null
): number {
  if (!relations) return 0;

  let reduction = 0;
  const today = new Date();
  for (let r of relations) {
    const allocDate = new Date(r.allocationDate);
    //const expDate = new Date(r.expirationDate);
    const expDate = addYears(allocDate, 1);
    if (isAfter(today, expDate) && !isThisMonth(expDate)) continue;

    if (isThisMonth(allocDate)) {
      reduction += r.offsetting;
    }
    // const start = startOfMonth(isThisMonth(allocDate) ? allocDate : today);
    // const end = isThisMonth(expDate) ? expDate : endOfMonth(today);
    // const numDays = differenceInDays(expDate, allocDate);
    // const d = differenceInDays(end, start) + 1;
    // const dayAmount = r.offsetting / numDays;
    // reduction += dayAmount * d;
  }
  return reduction;
}

export function getInitials(s: string | undefined, fallback?: string): string {
  if (!s) return fallback ?? "";
  const parts = s.split(" ");
  if (parts.length > 1) {
    return (
      parts[0].substring(0, 1).toUpperCase() +
      parts[parts.length - 1].substring(0, 1).toUpperCase()
    );
  } else {
    return parts[0].substring(0, 2).toUpperCase();
  }
}

export enum SocialMedia {
  Facebook = "facebook",
  Twitter = "Twitter",
  LinkedIn = "LinkedIn",
  XING = "XING",
  Instagram = "Instagram",
  YouTube = "Youtube",
  Snapchat = "Snapchat",
  TikTok = "TikTok",
}

export type SocialMediaObj = {
  type: SocialMedia;
  value: string;
};

export function convertPrismDateToStringDate(v: any | null | undefined) {
  if (!v) return "";
  if (typeof v === "string") {
    return v.substring(0, 10);
  } else if (v instanceof Date) {
    return v.toISOString().substring(0, 10);
  }
}

export function isSocialMediaActive(
  m: SocialMedia,
  obj?: Profile["socialMedia"]
): boolean {
  if (!obj || typeof obj !== "object" || Array.isArray(obj) || !(m in obj))
    return false;

  const objElement = obj[m];
  if (isSocialMediaObj(objElement)) {
    return objElement.value != null && objElement.value !== "";
  }

  return false;
}

export function getSocialMediaObj(
  type?: SocialMedia,
  obj?: Profile["socialMedia"]
): SocialMediaObj | undefined {
  if (
    !type ||
    !obj ||
    typeof obj !== "object" ||
    Array.isArray(obj) ||
    !(type in obj)
  )
    return undefined;

  const objElement = obj[type];
  if (isSocialMediaObj(objElement)) return objElement;
  return undefined;
}

function isSocialMediaObj(data?: any): data is SocialMediaObj {
  return data && typeof data === "object" && "type" in data && "value" in data;
}

const kgFormatOptions = {
  style: "unit",
  unit: "kilogram",
  maximumFractionDigits: 2,
  minimumFractionDigits: 0,
} as const;

const dateFormatOptions: DateTimeFormatOptions = {
  dateStyle: "medium",
};

const dateTimeFormatOptions: DateTimeFormatOptions = {
  dateStyle: "medium",
  timeStyle: "short",
} as const;

export const FormatOptions = {
  kg: kgFormatOptions,
  date: dateFormatOptions,
  dateTime: dateTimeFormatOptions,
};

export async function handleFilterSubmit(
  e: FormEvent<HTMLFormElement>,
  router: NextRouter
) {
  e.preventDefault();
  // @ts-ignore this is allowed, see: https://developer.mozilla.org/en-US/docs/Web/API/URLSearchParams/URLSearchParams
  const search = new URLSearchParams(window.location.search);

  const filterFormData = new FormData(e.currentTarget);
  filterFormData.forEach((value, key) => {
    if (typeof value === "string" && value !== "")
      search.set(key, value.toString());
    else search.delete(key);
  });
  search.delete("page");

  await router.replace("?" + search.toString());
}

export const SORT_QUERY_PREFIX = "s_";

/*
Dieser Monat: 1. des Monat to today
Dieses Jahr: 01.01 to today
Letzten 12 Monate: today -12m (default)
Gesamt:
 */
export type TimeFilerValue = "last12M" | "thisY" | "thisM" | "all";
export const TIME_FILTER_VALUES = ["last12M", "thisY", "thisM", "all"];

export function isTimeFilterValue(value?: string): value is TimeFilerValue {
  if (value == null) return false;
  return TIME_FILTER_VALUES.includes(value);
}

export function getTimeFilterValue(value?: string): TimeFilerValue {
  return isTimeFilterValue(value) ? value : "last12M";
}

export function splitIntoSubArrays<T>(
  originalArray: T[],
  entries: number = 3,
  maxRows?: number
): T[][] {
  let result: T[][] = [];
  let temp: T[] = [];

  for (let i = 0; i < originalArray.length; i++) {
    temp.push(originalArray[i]);
    if (temp.length === entries) {
      result.push(temp);
      temp = [];
    }
    if (maxRows != null && result.length === maxRows) {
      break;
    }
  }

  // Push the last array if it has less than 3 items
  if ((maxRows == null || result.length < maxRows) && temp.length > 0) {
    result.push(temp);
  }

  return result;
}

export const CookieCalculatorQuestionSessionKey =
  process.env.NODE_ENV === "development" ? "hgf-qs" : "__HOST-hgf-qs";

export function capitalize(s: string): string {
  return s.charAt(0).toUpperCase() + s.slice(1);
}

export function getTranslation(
  key: string,
  locale: string,
  translations: Translation[],
  defaultValue?: string | null
) {
  const t = translations.find((t) => t.key === key && t.language === locale);
  return t?.value ?? defaultValue;
}

export function subId(id: string) {
  return id.substring(0, 8);
}

export function idCell(
  id: string,
  baseLink: string
): CellObject & CellLinkObject {
  let href = baseLink;
  if (!href.endsWith("/")) {
    href += "/";
  }
  return {
    value: subId(id),
    href: href + id,
  };
}

export function getHash(items: (string | number | Buffer)[]) {
  const hash = createHash("sha256");
  for (let item of items) {
    if (typeof item === "number") hash.update(String(item));
    else {
      hash.update(item);
    }
  }
  // See https://en.wikipedia.org/wiki/Base64#Filenames
  return hash.digest("base64").replace(/\//g, "-");
}

export const CalculatorQuestionQueryId = "co";

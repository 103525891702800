import type { ReactNode } from "react";
import styles from "./Bold.module.scss";

export type BoldProps = ReactNode;

function Bold(children: BoldProps) {
  return <span className={styles.b}>{children}</span>;
  // return <span className="__bold">{children}</span>;
}

export default Bold;

import { faArrowRightFromBracket } from "@fortawesome/pro-regular-svg-icons/faArrowRightFromBracket";
import { faGear } from "@fortawesome/pro-solid-svg-icons/faGear";
import { faUser } from "@fortawesome/pro-solid-svg-icons/faUser";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Menu, MenuDivider, MenuItem } from "@szhsin/react-menu";
import clsx from "clsx";
import Image from "next/image";
import { useRouter } from "next/router";
import { useTranslations } from "use-intl";
import useLogout from "../../hooks/useLogout";
import useSessionUser from "../../hooks/useSessionUser";
import useUtilTranslations from "../../hooks/useUtilTranslations";
import { getInitials } from "../../util";
import styles from "./ProfileMenu.module.scss";

export type ProfileMenuProps = {};

function ProfileMenu({}: ProfileMenuProps) {
  const t = useTranslations("components.Layout");
  const logout = useLogout();
  const tUtil = useUtilTranslations();
  const { userData: user } = useSessionUser();
  const router = useRouter();

  return (
    <Menu
      align="end"
      direction="bottom"
      arrow
      menuButton={
        <div className={styles.button}>
          {user?.image ? (
            <Image
              height={48}
              width={48}
              src={location.origin + "/api/user/p/" + user.image}
              alt={user.name || "Avatar"}
            />
          ) : (
            <span>{getInitials(user?.name, "U")}</span>
          )}
        </div>
      }
      transition
      menuClassName={styles.menuContainer}
    >
      <MenuItem onClick={() => router.push("/profile")}>
        <FontAwesomeIcon className={clsx(styles.buttonIcon)} icon={faUser} />
        {t("nav.profile")}
      </MenuItem>
      <MenuDivider />
      {user?.isAdmin && (
        <>
          <MenuItem onClick={() => router.push("/admin")}>
            <FontAwesomeIcon
              className={clsx(styles.buttonIcon)}
              icon={faGear}
            />
            {t("nav.admin")}
          </MenuItem>
          <MenuDivider />
        </>
      )}
      <MenuItem onClick={logout}>
        <FontAwesomeIcon
          icon={faArrowRightFromBracket}
          className={clsx(styles.buttonIcon, styles.logoutIcon)}
        />
        {tUtil("action.logout")}
      </MenuItem>
    </Menu>
  );
}

ProfileMenu.Messages = ["components"];

export default ProfileMenu;
